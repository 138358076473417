import React, { useState } from "react";

const BidInput = ({ amount, step, fieldName }) => {
  const [value, setValue] = useState(amount + step);

  const incr = () => setValue(value + step);
  const decr = () => setValue(value - step);

  const formatedValue = new Intl.NumberFormat("es-PY").format(value)
  const minReached = value <= amount + step;
  return (
    <div className="flex flex-row justify-center border rounded-xl p-4">
      <input name={fieldName} type="hidden" value={value} />
      <span className="text-3xl text-right w-40">{formatedValue}</span>
      <button className="btn btn-primary ml-2" type="button" onClick={incr}>
        +
      </button>
      <button className="btn btn-primary ml-2" type="button" onClick={decr} disabled={minReached}>
        -
      </button>
    </div>
  );
};

export default BidInput;
